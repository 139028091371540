import Mock from "../mock";

const database = {
  information: {
    name: 'Myke Black',
    aboutContent: "Tech lead, games developer, web designer & infrastructure engineer",
    age: 0,
    phone: '',
    nationality: 'British',
    language: 'English, Danish, French',
    email: 'myke.black@gmail.com',
    address: '37 Clee Road, Cleethorpes, England',
    freelanceStatus: 'Not Available',
    contractStatus: 'Not Available',
    socialLinks: {
      facebook: 'https://facebook.com/MykeBlack',
      twitter: 'https://twitter.com/mykeblack',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/myke-black/',
      dribbble: '',
      github: 'https://github.com/mykeblack'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: 'https://docs.google.com/document/d/1O3I18YUKSsJxCh-0sdselJq-GLj7f6vX/edit?usp=sharing&ouid=104326288015353190932&rtpof=true&sd=true'
  },
  services: [
    {
      title: "Technical Leadership",
      icon: 'user',
      details: "Technical team leader, certified scrum master, engineering leadership and mentor."
    },
    {
      title: "Games Engineering",
      icon: 'game',
      details: "Games designer competent in Unity, Unreal, HTML5 and Javascript"
    },
    {
      title: "Front End Web Design",
      icon: 'pallet',
      details: "Front end web design using Javascript, React, Angular, Vuejs, jQuery and web components"
    },
    {
      title: "Backend Web Services",
      icon: 'code',
      details: "Backend web apis and server side programming engineered in .Net Core (C#), Nodejs, PHP"
    },
    {
      title: "Cloud Architecture",
      icon: 'cloud',
      details: "Design and development of large scale distributed architectures. AWS certified solutions architect"
    },
    {
      title: "Tooling & Automation",
      icon: 'cog',
      details: "Tooling and automation development to facilitate faster and more efficient workflow"
    }

  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "Unity",
      value: 85
    },
    {
      title: "Unreal",
      value: 75
    },
    {
      title: "HTML5",
      value: 98
    }
    ,
    {
      title: "CSS3",
      value: 94
    },
    {
      title: "Javascript",
      value: 91
    },
    {
      title: "c#",
      value: 97
    },
    {
      title: "Azure",
      value: 70
    },
    {
      title: "AWS",
      value: 95
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Next PLC",
      subtitle: "Global online ecom retailer.",
      imageUrl: "/images/portfolio-image-1.jpg",
      url: 'https://next.co.uk'
    },
    {
      id: 2,
      title: "ScubaGo",
      subtitle: "International travel site for scuba diving holidays",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://scubago.com'
    },
    {
      id: 3,
      title: "SSI International",
      subtitle: "The world's largest professional dive business-based training agency",
      imageUrl: "/images/portfolio-image-3.jpg",
      url: 'https://divessi.com'
    },
    {
      id: 4,
      title: "Mortgage Key",
      subtitle: "Expert mortgage broker and mortage comparison services",
      imageUrl: "/images/portfolio-image-4.jpg",
      url: 'https://mortgagekey.co.uk'
    },
    {
      id: 5,
      title: "Zenith Vehicles",
      subtitle: "Intelligent vehicle solutions",
      imageUrl: "/images/portfolio-image-5.jpg",
      url: 'https://zenith.co.uk'
    },
    {
      id: 6,
      title: "Key IVR",
      subtitle: "PCI Compliant Payment Solutions & Agent Assisted Payments",
      imageUrl: "/images/portfolio-image-6.jpg",
      url: 'https://keyivr.co.uk'
    },
    {
      id: 7,
      title: "Blackbox",
      subtitle: "Full service marketing",
      imageUrl: "/images/portfolio-image-7.jpg",
      url: 'https://blackboxecom.com'
    },
    {
      id: 8,
      title: "HealthShare",
      subtitle: "AI Powered online physiotherapy tools",
      imageUrl: "/images/portfolio-image-8.jpg"
    },
    {
      id: 9,
      title: "One Healthcare",
      subtitle: "Hospital group websites",
      imageUrl: "/images/portfolio-image-9.jpg",
      url: 'https://onehealthcare.co.uk'
    },
    {
      id: 10,
      title: "The ISEH",
      subtitle: "Institute of Sport and Exercise Health",
      imageUrl: "/images/portfolio-image-10.jpg",
      url: 'https://iseh.co.uk'
    },
    {
      id: 11,
      title: "PipesCo",
      subtitle: "Specialists in maintenance and tier 1 engineering solutions",
      imageUrl: "/images/portfolio-image-11.jpg",
      url: 'https://pipesco.com'
    },
    {
      id: 12,
      title: "TRB Chemedica",
      subtitle: "Rheumatology and ophthalmology products",
      imageUrl: "/images/portfolio-image-12.jpg",
      url: 'https://trbchemedica.com'
    }
  ],
  games: [
    {
        id: 20,
        title: "Star Wars Hunters",
        subtitle: "Star Wars Arena Combat game for Switch, iOS and Android developed by Boss Alien/Zynga coming soon",
        imageUrl: "/images/game-image-20.jpg",
        url: 'https://starwarshunters.com/',        
        download: ''
    },
    {
        id: 19,
        title: "Dawn of Titans",
        subtitle: "Fantasy action strategy game developed by Natural Motion",
        imageUrl: "/images/game-image-19.jpg",
        url: '',
        download: ''
    },
    {
        id: 18,
        title: "Thrust game prototype",
        subtitle: "Using new Unity input system for keyboard, PS5 and xbox controllers",
        imageUrl: "/images/game-image-18.jpg",
        url: 'https://play.unity.com/mg/other/webgl-builds-21623',
        download: ''
    },
    {
    id: 17,
    title: "Photon Tester",
    subtitle: "Online Multiplayer Demo using Photon (PUN) and Unity",
    imageUrl: "/images/game-image-17.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-21043',
    download: ''
    },
    {
    id: 16,
    title: "Rogue Life",
    subtitle: "2D puzzle game built from the Scavengers project tutorial on Unity Learn",
    imageUrl: "/images/game-image-16.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-20690',
    download: ''
  },
  {
    id: 15,
    title: "Attack on Galantia",
    subtitle: "3D tower defence game",
    imageUrl: "/images/game-image-15.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-19805',
    download: ''
  },{
    id: 14,
    title: "Desert Tanks",
    subtitle: "2 player arcade game",
    imageUrl: "/images/game-image-14.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-19474',
    download: ''
  },{
    id: 13,
    title: "Lumpy's Quest",
    subtitle: "2D RPG game",
    imageUrl: "/images/game-image-13.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-19412',
    download: ''
  },{
    id: 12,
    title: "Kings Ransom",
    subtitle: "Coin drop microgame",
    imageUrl: "/images/game-image-12.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-17927',
    download: ''
  },{
    id: 11,
    title: "Clicky Game",
    subtitle: "Hit the boxes, not the skulls",
    imageUrl: "/images/game-image-11.jpg",
    url: 'https://play.unity.com/mg/other/build-hw0',
    download: ''
  },
  {
    id: 10,
    title: "Invasion of the Beagles",
    subtitle: "Defend the farm from the hungry beagles",
    imageUrl: "/images/game-image-10.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-15905',
    download: ''
  },
  {
    id: 9,
    title: "Part time punks",
    subtitle: "Animation and particle system experiments",
    imageUrl: "/images/game-image-9.jpg",
    url: 'https://play.unity.com/mg/other/build-6oh',
    download: ''
  },
  {
    id: 1,
    title: "Captain Blackstar",
    subtitle: "Space shooter game",
    imageUrl: "/images/game-image-1.jpg",
    url: 'https://play.unity.com/mg/other/webgl-builds-19923',
    download: ''
  },
  {
    id: 2,
    title: "EskApe",
    subtitle: "Puzzle/rpg game - in development",
    imageUrl: "/images/game-image-2.jpg",
    download: ''
  },
  {
    id: 3,
    title: "Pinball Powertrip",
    subtitle: "Casual mobile game - in development",
    imageUrl: "/images/game-image-3.jpg",
    download: ''
  },
  {
    id: 4,
    title: "Chaos - Battle of Wizards",
    subtitle: "Remake of the classic spectrum game",
    imageUrl: "/images/game-image-4.jpg",
    url: '/games/chaos/index.html',
    download: 'https://drive.google.com/file/d/1DtNP63R5x0WGaFnwBQGAwQTXwNbyEYcQ/view?usp=sharing'
  },
  {
    id: 5,
    title: "Lexelle Rider",
    subtitle: "2d sideways scroller motorbike game",
    imageUrl: "/images/game-image-5.jpg",
    download: '/files/games/lexelle.zip'
  },
  {
    id: 6,
    title: "Biplanes MX",
    subtitle: "Aerial combat game for 1 or 2 players",
    imageUrl: "/images/game-image-6.jpg",
    download: '/files/games/biplanes.zip'
  },
  {
    id: 7,
    title: "World Cup Penalty Shootout",
    subtitle: "Penalty shootout game for 1 or 2 players",
    imageUrl: "/images/game-image-7.jpg",
    download: '/files/games/worldcup.zip'
  },
  {
    id: 8,
    title: "Wormwood Cricket",
    subtitle: "Cricket game made for Wormwood",
    imageUrl: "/images/game-image-8.jpg",
    url: 'https://www.woodworm.tv',
    download: ''
  }
],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "04/2021 - Present",
        position: "Technical Lead Engineer",
        company: "Take Two Interactive / Zynga / Natural Motion - London",
        details: "Games engineer focusing on tooling, infrastructure, platform, networking and dev ops. Scrum master and mentor for new starters and interns. AWS certified solutions architect."
      },
      {
        id: 2,
        year: "07/2020 - 04/2021",
        position: "Consultant Developer",
        company: "SSI International - Wendelstein, Germany",
        details: "Create customised PHP components and rendering systems for rapid application development. Optimised code and database queries to cope with big data, and high traffic mobile apps."
      },
      {
        id: 3,
        year: "08/2019 - 07/2020",
        position: "Remote Lead Developer and Scrum Master ",
        company: "Scubago.com - Amsterdam, Netherlands",
        details: "International travel website built in .Net Core 3 and hosted as an Azure app, with Azure search, messaging services and storage"
      },
      {
        id: 4,
        year: "2/2019 - 8/2019",
        position: "Contract Lead Developer",
        company: "Next Plc - Doncaster, UK",
        details: "Next is an international ecommerce company selling clothing, furnature and home products. My role here was to add new features to the checkout and delivery options on the website"
      },
      {
        id: 5,
        year: "10/2018 - 2/2019",
        position: "Freelance Developer",
        company: "Mortgage Key - Hull, UK",
        details: "Upgraded legacy admin systems for the mortgage broker's SaaS platform to more modern .net tech stacks"
      },
      {
        id: 6,
        year: "7/2018 - 10/2018",
        position: "Senior .Net Developer",
        company: "Zenith Vehicle Solutions - Leeds, UK",
        details: "Improved multi quote system built in Angular to optimise front end responsiveness, fix bugs, and created new custom third party APIs."
      },
      {
        id: 7,
        year: "9/2017 - 07/2018",
        position: "Contract Senior Developer ",
        company: "Key IVR Ltd - Doncaster, UK",
        details: "Worked on IVR technology to build VOIP payment solutions and created new payment gateway APIs."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "AWS",
        position: "AWS Cloud Practitioner"
      },
      {
        id: 2,
        year: "AWS",
        position: "AWS Soluitions Architect"
      },
      {
        id: 3,
        year: "Scrum.org",
        position: "Certified Scrum Master"
      }
    ]
  },
  blogs: [
    
  ],
  contactInfo: {
    phoneNumbers: ['+44 (0)7884111942'],
    emailAddress: ['myke.black@gmail.com'],
    address: "37 Clee Road, Cleethorpes, North East Linconlshire, UK, DN35 8AD"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/games").reply(config => {
  const response = database.games;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});